import axiosApi from '@/api/axiosApi';

export const getPage = () => {
    return axiosApi({
        url: '/intserv/software/getPage',
        method: 'get'
    })
}

export const getSoftByLabId = (id) => {
    return axiosApi({
        url: `/intserv/software/getSoftByLabId/${id}`,
        method: 'get'
    })
}

export const getSoftsByInfo = (data) => {
    return axiosApi({
        url: `/intserv/software/getSoftsByInfo`,
        method: 'post',
        data
    })
}